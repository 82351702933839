<template>
  <div>
    <div v-if="listings && listings.length > 0">
      <h2 class="directory--listing-title mb-2 px-1" v-if="propertyType">
        {{ propertyType.name }} {{ $t('directory.listing.' + listingType) }}
      </h2>
      <div class="directory--listing-card">
        <card-listing
          class="mb-4"
          v-for="listing in listings"
          :key="listing.id"
          :listing="listing"
          :showCompare="false"
          :allowMultiImage="true"
        />
      </div>
      <div class="directory--listing-all mt-2">
        <router-link :to="seeAllPath(listingType, propertyType)"
          >{{ propertyType.name }} {{ $t('directory.listing.' + listingType) }} ></router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
const CardListing = () => import('@/components/utils/card-listing');
import { mapState } from 'vuex';
export default {
  components: {
    CardListing,
  },
  props: {
    listingType: {
      type: String,
      required: true,
    },
    propertyType: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({}),
  },
  data: () => ({
    listings: [],
  }),
  async mounted() {
    await this.getListings();
  },
  methods: {
    async getListings() {
      let propertyType = await this.$store.dispatch(
        'v2/masters/getPropertyTypeByName',
        this.$route.params.propertyType,
      );
      this.listings = await this.$store.dispatch('v2/directory/getDirectoryListing', {
        listingType: this.listingType,
        propertyType: propertyType,
      });
    },
    seeAllPath(listingType, propertyType) {
      return encodeURI(
        '/' +
          (listingType === 'R' ? 'rent' : 'buy') +
          '/search/' +
          (propertyType.name ? propertyType.name.toLowerCase() : 'all'),
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
